import React from "react";
import ReactDOM from 'react-dom';
import {Image} from "antd";
import "./helpersForPopup.css"
import {setNestedArrayIntoFlatArray} from "../utils/utils"

export const getPopupDOMContent = (popupDataJSON, data, analysisType = undefined, bubbleMapType = undefined) => {

    const popupData = JSON.parse(popupDataJSON);

    const functions_multiVariables = [
        'shannon_diversity_index',
        'simpson_diversity_index',
        'inverse_simpson_diversity_index',
        'arithmetic_index'
    ];
    const isMultiVar = (variable) => functions_multiVariables.includes(variable.popupVariableFunction)

    const isAreaMap = analysisType === 'ChoroplethMap' || 
        (analysisType === 'BubbleMap' && bubbleMapType === 'area');

    const popupContainerElement = document.createElement('div');
    popupContainerElement.classList.add('custom-popup-divContainerElement');

    //displaying title
    const titleElement = get_titleElement(popupData.title);
    popupContainerElement.appendChild(titleElement);

    //line
    const lineElement = document.createElement('hr');
    lineElement.classList.add('custom-popup-lineElement');
    popupContainerElement.appendChild(lineElement);

    //actual data
    const theData = data.data[0][0];

    //the region label
    const label = data.data[0][0]['level'];
    const variableValue = data.data[0][0]['name'];
    const variable = {alias: label, dataType: 'TEXT'};
    const regionElement = get_pElement(variable, variableValue, analysisType);
    popupContainerElement.appendChild(regionElement);


    //displaying variables
    //main block
    const dataSchemaColumnNormalizedNames = data.dataSchemas[0].columnNormalizedNames;
    const popupVariablesNonQB = popupData.variables
        .filter(variable => {            
            return dataSchemaColumnNormalizedNames.includes(
                isMultiVar(variable) 
                    ? variable.category 
                    : variable.popupVariable);
        })
        ?.map(variable => {
            let dataType;
            if (isMultiVar(variable)) {
                dataType = 'DOUBLE'; //SQL TYPE: DOUBLE_PRECISION                
            } else {
                const index = dataSchemaColumnNormalizedNames.findIndex(nameNormalized =>
                    nameNormalized === variable.popupVariable);
                dataType = data.dataSchemas[0].dataTypes[index];                
            };
            return {
                variable: isMultiVar(variable) 
                    ? variable.category 
                    : variable.popupVariable, 
                alias: variable.popupVariableAlias, 
                dataType: dataType,
                requiredType: variable?.requiredType
            }
        });

    const getActualKey = (key) => {
        const actualKey = key.split('_@_');
        if (actualKey.length === 1) {
            return {variable: actualKey[0]};
        } else {
            return {variable: actualKey[0], alias: actualKey[1]};
        }
    };

    popupVariablesNonQB.forEach(variable => {
        const excludedFieldsFromTheData = ['id', 'date_created']; 
        const keys = Object.keys(theData).filter(key => !excludedFieldsFromTheData.includes(key));
        const isRequiredTypeInteger = variable?.requiredType === 'integer';
        let variableValue = keys.map(key => {
            const actualKey = getActualKey(key);
            let isVariableMatched = false;
            if (Object.keys(actualKey).length === 1) {
                isVariableMatched = actualKey.variable === variable.variable
            } else {
                isVariableMatched = actualKey.variable === variable.variable && actualKey.alias === variable.alias
            };
            if (isVariableMatched) { 
                return isRequiredTypeInteger ? Math.round(theData[key]): theData[key]
            } else {
                return null
            };
        }).filter(value => value !== null)[0];

        let variableElement;
        switch (variable.dataType) {
            case 'PHOTO':
                variableElement = get_imgElement(variable, variableValue, analysisType);
                break;
            
            default:
                variableElement = get_pElement(variable, variableValue, analysisType);
        }

        popupContainerElement.appendChild(variableElement)
    });

    //qb block
    /**
     * The below code is filtering through a data object and checking for variables that match certain conditions. 
     * It is doing this by looping through the "dataSchemas" array and comparing it with the "popupData" variables. 
     * It then creates an array of objects that contain the variables, their aliases, and their data types. 
     * Once the array of objects is created, the code then checks for the analysis type and creates a dataQB object based 
     * on the type. Finally, the code loops through the dataQB object and creates elements with the variables, their aliases, 
     * and their data types.
     */
    if (data.dataSchemas.length > 1) {
        const dataSchemaQBColumnNormalizedNames = data.dataSchemas[1].columnNormalizedNames;
        const popupVariablesQB = popupData.variables
            .filter(variable => {                
                return variable.popupVariable !== 'id' && variable.popupVariable !== 'date_created';
            })
            .filter(variable => {                
                let testVariable;
                switch (variable.popupVariable) {
                    case 'idQB':
                        testVariable = 'id';
                        break;
                    case 'date_createdQB':
                        testVariable = 'date_created';
                        break;
                    default:
                        testVariable = isMultiVar(variable) 
                            ? variable.category 
                            : variable.popupVariable
                };                

                return dataSchemaQBColumnNormalizedNames.includes(testVariable);
            })
            ?.map(variable => {                
                let dataType;
                if (isMultiVar(variable)) {                    
                    dataType = 'DOUBLE'; //SQL TYPE: DOUBLE_PRECISION                
                } else {
                    const index = dataSchemaQBColumnNormalizedNames.findIndex(nameNormalized => {
                        let testVariable;
                        switch (variable.popupVariable) {
                            case 'idQB':
                                testVariable = 'id';
                                break;
                            case 'date_createdQB':
                                testVariable = 'date_created';
                                break;
                            default:
                                testVariable = variable.popupVariable
                        }

                        return nameNormalized === testVariable
                    });

                    dataType = data.dataSchemas[1].dataTypes[index];
                };
                
                return {
                    variable: isMultiVar(variable)
                        ? variable.category
                        : variable.popupVariable,
                    alias: variable.popupVariableAlias,
                    dataType: dataType,
                    requiredType: variable?.requiredType
                };
            });

        if (popupVariablesQB?.length > 0) {
            
            //dataQB
            let dataQB = null;

            switch (analysisType) {
                case 'BubbleMap':
                case "CategoricalPointMap": 
                case "SimplePointMap":
                case 'ChoroplethMap':
                    dataQB= [theData];
                    break;
                default:
                    const nameNormalizedQB = data.dataSchemas[1].nameNormalized;
                    dataQB = theData[nameNormalizedQB];

                    if (dataQB?.length > 0) {
                        const lineElement = document.createElement('hr');
                        lineElement.classList.add('custom-popup-lineElement');
                        popupContainerElement.appendChild(lineElement)
                    }

            }

            //loop over dataQB
            dataQB.forEach((datumQB, index) => {
                //loop over popupVariablesQB
                popupVariablesQB.forEach(variable => { 
                    const excludedFieldsFromDataQB = ['idQB', 'date_createdQB'];
                    const keys = Object.keys(datumQB).filter(key => !excludedFieldsFromDataQB.includes(key));
                    const isRequiredTypeInteger = variable?.requiredType === 'integer';

                    //set variableValue
                    let variableValue = keys.map(key => {                        

                        const actualKey = getActualKey(key);
                        let isVariableMatched = false;
                        if (Object.keys(actualKey).length === 1) {
                            isVariableMatched = actualKey.variable === variable.variable
                        } else {
                            isVariableMatched = actualKey.variable === variable.variable && actualKey.alias === variable.alias
                        };
                        if (isVariableMatched) {
                            return isRequiredTypeInteger ? Math.round(datumQB[key]) : datumQB[key]
                        } else {
                            return null
                        };
                    }).filter(value => value !== null)[0];

                    let variableElement;
                    switch (variable.dataType) {
                        case 'PHOTO':
                            variableElement = get_imgElement(variable, variableValue, analysisType);
                            break;
                        default:
                            variableElement = get_pElement(variable, variableValue, analysisType)
                    }

                    popupContainerElement.appendChild(variableElement);
                });

                if (!isAreaMap) {
                    if (index < dataQB.length) {
                        const lineElement = document.createElement('hr');
                        lineElement.classList.add('custom-popup-lineElement');
                        popupContainerElement.appendChild(lineElement)
                    }
                }
            });
        }
    }

    return popupContainerElement
};

const get_titleElement = (title) => {
    const pElement = document.createElement('p');
    pElement.classList.add('custom-popup-titleElement');

    const spanElementAlias = document.createElement('span');
    spanElementAlias.classList.add('custom-popup-title');
    spanElementAlias.innerText = title;
    pElement.appendChild(spanElementAlias);

    return pElement
};

const updateVariableValue = (variableDataTypeArg, variableValue, analysisType) => {
    let variableDataType = JSON.parse(JSON.stringify(variableDataTypeArg))
    const isJSONVariableNull = variableValue?.value
        ? variableValue.value === "[null]" : false;
    const isVariable = variableValue !== null && variableValue !== undefined &&
        variableValue !== "" && !isJSONVariableNull;
    if (isVariable) {
        if (variableDataType !== 'JSON' &&
            Object.keys(variableValue)?.includes('type') &&
            variableValue['type'] === 'json') {
            variableDataType = 'JSON'
        };
        switch (analysisType) {           
            case 'ChoroplethMap':
                switch (variableDataType) {
                    case 'JSON':
                        const keys = Object.keys(variableValue);
                        if (keys.includes('value')) {
                            variableValue = JSON.parse(variableValue.value);
                            variableValue = [...new Set(setNestedArrayIntoFlatArray(variableValue))].sort((a,b) =>
                                a === b ? 0 : a < b ? -1 : 1).join(", ");
                        }
                        break;
                    case 'DATETIME':
                        const regExp = RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}(?=.*$)', 'g');
                        if (variableValue?.value) {
                            let tempValue = [];
                            variableValue = JSON.parse(variableValue.value).forEach(value => tempValue.push(...value));
                            variableValue = [...new Set(tempValue)].sort((a,b) => a === b ? 0 : a < b ? -1 : 1);
                            variableValue = variableValue.map(value => value.match(regExp)).join(', ');
                        }
                        break;
                    default:
                    //do nothing
                }
                break;
            default:
                switch (variableDataType) {
                    case 'JSON':
                        const keys = Object.keys(variableValue);
                        if (keys.includes('value')) {
                            variableValue = JSON.parse(variableValue.value).join(", ")
                        }
                        break;
                    case 'DATETIME':
                        const regExp = RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}(?=.*$)', 'g');
                        variableValue = variableValue.match(regExp);
                        break;
                    default:
                    //do nothing
                }
        }
    } else {
        variableValue = 'No data'
    }

    return variableValue
};

const get_pElement = (variable, variableValue, analysisType) => {
    const pElement = document.createElement('p');
    pElement.classList.add('custom-popup-pElement');

    const spanElementAlias = document.createElement('span');
    spanElementAlias.classList.add('custom-popup-alias');
    spanElementAlias.innerText = variable.alias+ ': ';
    pElement.appendChild(spanElementAlias);

    const spanElementValue = document.createElement('span');
    spanElementValue.classList.add('custom-popup-value');
    spanElementValue.innerText = updateVariableValue(variable.dataType, variableValue, analysisType);
    pElement.appendChild(spanElementValue);

    return pElement
};

const get_imgElement = (variable, variableValue, analysisType) => {
    if (variableValue !== undefined && variableValue !== null && variableValue !== "") {
        const pElement = document.createElement('p');
        pElement.classList.add('custom-popup-pElement');

        const spanElementAlias = document.createElement('span');
        spanElementAlias.classList.add('custom-popup-alias');
        spanElementAlias.innerText = variable.alias+ ': ';
        pElement.appendChild(spanElementAlias);

        const imgDivContainer = document.createElement('div');
        imgDivContainer.classList.add('custom-popup-imgDivContainer');

        const imgJSX = <Image width={150} src={`data:${variableValue}`}/>;
        ReactDOM.render(imgJSX, imgDivContainer);

        const imageElement = document.createElement("div");
        imageElement.classList.add('custom-popup-imageElement');

        imageElement.appendChild(pElement);
        imageElement.appendChild(imgDivContainer);

        return imageElement
    } else {
        return get_pElement(variable, variableValue, analysisType)
    }
};

export const getPopupDOMContentRegion = (level, name, analysisType) => {

    const popupContainerElement = document.createElement('div');
    popupContainerElement.classList.add('custom-popup-divContainerElement');

    //region
    const label = level;
    const variableValue = name;
    const variable = {alias: label, dataType: 'TEXT'};
    const regionElement = get_pElement(variable, variableValue, analysisType);
    popupContainerElement.appendChild(regionElement);

    return popupContainerElement
};
