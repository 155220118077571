import {Layout} from "antd";
import {FormattedMessage} from "react-intl";

const {Content, Footer} = Layout;

function getLanguage() {
    let varLanguage = 'en'
    if (localStorage.getItem("language") === undefined) {
        const lang = window.navigator.language;
        if (lang && lang.startsWith('es')) {
            varLanguage = 'es'
        }
    } else if (localStorage.getItem("language") === 'es') {
        varLanguage = 'es'
    }
    return varLanguage;
}

const Home = (props) => {

    const REACT_APP_NAME = process.env.REACT_APP_NAME;
    const REACT_APP_TEXT_A1 = getLanguage() === 'es' ? process.env.REACT_APP_TEXT_A1_ES : process.env.REACT_APP_TEXT_A1;
    const REACT_APP_TEXT_A2 = getLanguage() === 'es' ? process.env.REACT_APP_TEXT_A2_ES :  process.env.REACT_APP_TEXT_A2;
    const REACT_APP_TEXT_B1 = getLanguage() === 'es' ? process.env.REACT_APP_TEXT_B1_ES :  process.env.REACT_APP_TEXT_B1;

    return (
        <Layout>
            <link rel="stylesheet" href="./configurable/contents/css/bootstrap.min.css"/>
            <link rel="stylesheet" href="./configurable/contents/css/styles.css"/>
            {/*<!--  =============    Banner Section  ==========    -->*/}
            <div className="bannersSection">

                {/*<!--  =============    Banner Section INDEX  ==========    -->*/}
                <div className="container-fluid banner-img">
                </div>

            </div>
            <Content className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                <>

                    <div className="containersSection">

                        <div id="indexSection">


                            {/*<!-- ===  Section V1: Platform Presentation Section   =============  -->*/}
                            <section id="register-organization">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                        </div>
                                        <div className="col-lg-6 image">
                                            <img src="./configurable/contents/images/image_A.png"
                                                 alt="image_A.png" width="200" height="160"/>
                                        </div>
                                        <div className="col-lg-6 text">
                                            <p>
                                                {REACT_APP_TEXT_A1}
                                            </p>
                                            <p>
                                                {REACT_APP_TEXT_A2}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/*<!-- ===  Section V1 End: Platform Presentation Section   =========  -->*/}

                            {/*<!-- ===  Section V2: Platform Overview Section   =================  -->*/}
                            <section id="presentation">

                                <div className="container dark">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h2 className="section-title">
                                                <FormattedMessage id="label.home_h1" defaultMessage="Real Time data collection with MyQueryForm
                                                and "/>{REACT_APP_NAME}</h2>
                                        </div>

                                        <div className="col-md-12">
                                            <p> {REACT_APP_TEXT_B1}</p>
                                        </div>

                                        <div className="col-md-12 video-responsive" align="center">
                                            <img src="./configurable/contents/images/image_B.png"
                                                 alt="image_B.png" style={{width: '80%', height: 'auto'}}/>
                                            {/*<script type="text/javascript" src="https://www.youtube.com/player_api"></script>*/}
                                            {/*<iframe title="Survey Builder" id="player" width="100%" height="650px" src="https://www.youtube.com/embed/XSmUnDXlIZk?rel=0&amp;enablejsapi=1" allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" frameborder="0" marginheight="30">*/}
                                            {/*</iframe>*/}
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/*<!-- ===  Section V2 End: Platform Overview   =============  -->*/}


                            {/*<!-- ===  Section V3: MyQueryForm Presentation   =========   -->*/}

                            <section id="register-organization">
                                <div className="container dark">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h2 className="section-title"><FormattedMessage id="label.home_h2" defaultMessage="We invite you to explore MyQueryForm app"/></h2>
                                        </div>
                                        <div className="col-lg-6 image">
                                            <img src="./configurable/contents/images/image_C.png"
                                                 alt="image_C.png" width="200" height="160"/>
                                        </div>
                                        <div className="col-lg-6 text">
                                            <p>
                                                <FormattedMessage id="label.home_h3" defaultMessage="Mobile app with demo survey form is included. To begin:"/></p>
                                            <ol>
                                                <li><FormattedMessage id="label.home_h4" defaultMessage="Download MyQueryForm app from"/> <a target="_blank"
                                                                                     rel="noreferrer"
                                                                                     href="https://apps.apple.com/us/app/myqueryform/id1361882359"><FormattedMessage id="label.home_h5" defaultMessage="Apple Store"/></a> <FormattedMessage id="label.home_h6" defaultMessage="or from"/> <a target="_blank"
                                                                         rel="noreferrer"
                                                                         href="https://play.google.com/store/apps/details?id=com.va.myqueryformnew"><FormattedMessage id="label.home_h7" defaultMessage="Google Play"/></a></li>
                                                <li><FormattedMessage id="label.home_h8" defaultMessage="Set URL: "/><span
                                                    className="text-danger">https://mydatanalysis.vectoranalytica.com</span>
                                                </li>
                                                <li><FormattedMessage id="label.home_h9" defaultMessage="Username: "/><span
                                                    className="text-danger">user2023</span>
                                                </li>
                                                <li><FormattedMessage id="label.home_h10" defaultMessage="Password: "/><span
                                                    className="text-danger">user2023@</span>
                                                </li>
                                                <li><FormattedMessage id="label.home_h11" defaultMessage="Tap FORMS and select form provided"/></li>
                                                <li><FormattedMessage id="label.home_h12" defaultMessage="Return to MAPS and tap red location marker to open demo form"/>
                                                </li>
                                                <li><FormattedMessage id="label.home_h13" defaultMessage="Complete form and SEND"/></li>
                                            </ol>


                                            {/*<div className="col-sm-12 col-md-12 requests">*/}
                                            {/*    <div className="row text-center">*/}
                                            {/*        <div className="col-md-4 mt-4">*/}
                                            {/*            <a data-href="contact" href="#" className="btn warning">Request*/}
                                            {/*                free trial</a>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}

                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/*<!-- ====  Section V3 End: MyQueryForm presentation   =======   -->*/}


                            {/*<!--  ====  Section V4: Main Videos  ==========    -->*/}

                            {/*<section id="videos" className="videos">*/}
                            {/*    <div className="container dark">*/}
                            {/*        <div className="row">*/}
                            {/*            <div className="col-lg-6 video-section">*/}
                            {/*                <div className="video-iframe">*/}
                            {/*                    <iframe title="Video1" src="https://www.youtube.com/embed/Kla1OJe0qQI"*/}
                            {/*                            style={{border: 0, width:'100%',height:'100%',position:'absolute',left:'0px'}}/>*/}
                            {/*                </div>*/}
                            {/*                <p>Donec id elit non mi porta gravida at eget metus. Maecenas faucibus mollis interdum.</p>*/}
                            {/*            </div>*/}

                            {/*            <div className="col-lg-6 video-section">*/}
                            {/*                <div className="video-iframe">*/}
                            {/*                    <iframe title="Video2" src="https://www.youtube.com/embed/bszyxL9Bwh8"*/}
                            {/*                            style={{border: 0, width:'100%',height:'100%',position:'absolute',left:'0px'}}/>*/}
                            {/*                </div>*/}
                            {/*                <p>Donec id elit non mi porta gravida at eget metus. Maecenas faucibus mollis interdum.</p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</section>*/}

                            {/*<section id="videosB" className="videos">*/}
                            {/*    <div className="container">*/}
                            {/*        <div className="row">*/}
                            {/*            <div className="col-lg-6 video-section">*/}
                            {/*                <div className="video-iframe">*/}
                            {/*                    <iframe frameBorder="0" title="Video1" src="https://www.youtube.com/embed/Mi3toIqESMc?list=PLu9XKktAHee9pd9obtdmTY3W8xVtmIWZh"*/}
                            {/*                            style={{width:'100%',height:'100%',position:'absolute',left:'0px'}}/>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}

                            {/*            <div className="col-lg-6 video-section">*/}
                            {/*                <h2 className="text-center">Subheading</h2>*/}
                            {/*                <p>Donec id elit non mi porta gravida at eget metus. Maecenas faucibus mollis interdum.</p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</section>*/}

                            {/*<!--  === Section V4 End: Main Videos    ==========    -->*/}

                            {/*<!--  === Section V5: Main Features Section  ==========    -->*/}
                            <section id="mainFeaturesSection">
                                <div className="container">
                                    <h2 className="section-title"><FormattedMessage id="label.home_h14" defaultMessage="Main features"/></h2>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 mainFeatureArticle">
                                            <img src="./configurable/contents/images/multiplatform.svg"
                                                 alt="multiplatform.svg"/>
                                            <div className="textRow">
                                                <h3 className="section-title"><FormattedMessage id="label.home_h15" defaultMessage="Multiplatform"/></h3>
                                                <div><FormattedMessage id="label.home_h16" defaultMessage="Web-Based and mobile device applications can run on UNIX, Windows or Mac OS."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 mainFeatureArticle">
                                            <img src="./configurable/contents/images/userFriendly.svg"
                                                 alt="userFriendly.svg"/>
                                            <div className="textRow">
                                                <h3 className="section-title"><FormattedMessage id="label.home_h17" defaultMessage="User-friendly"/></h3>
                                                <div><FormattedMessage id="label.home_h18" defaultMessage="Programmed in modular and user friendly interfaces, no mathematical skills required."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 mainFeatureArticle">
                                            <img src="./configurable/contents/images/customizable.svg"
                                                 alt="customizable.svg"/>
                                            <div className="textRow">
                                                <h3 className="section-title"><FormattedMessage id="label.home_h19" defaultMessage="Customizable"/></h3>
                                                <div><FormattedMessage id="label.home_h20" defaultMessage="Modules (and functionalities within each module) are contracted for according to client's specifications."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 mainFeatureArticle">
                                            <img src="./configurable/contents/images/flexible.svg" alt="flexible.svg"/>
                                            <div className="textRow">
                                                <h3 className="section-title"><FormattedMessage id="label.home_h21" defaultMessage="Flexible"/></h3>
                                                <div><FormattedMessage id="label.home_h22" defaultMessage="Allows quick modifications, configuration changes, additions and substitutions without significant changes in the system's overall architecture."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 mainFeatureArticle">
                                            <img src="./configurable/contents/images/scalable.svg" alt="scalable.svg"/>
                                            <div className="textRow">
                                                <h3 className="section-title"><FormattedMessage id="label.home_h23" defaultMessage="Scalable"/></h3>
                                                <div><FormattedMessage id="label.home_h24" defaultMessage="From neighborhood block to entire country-level --or even larger geographic regions, including arbitrary areas with non-administrative boundaries as defined by the client."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 mainFeatureArticle">
                                            <img src="./configurable/contents/images/secure.svg" alt="secure.svg"/>
                                            <div className="textRow">
                                                <h3 className="section-title"><FormattedMessage id="label.home_h25" defaultMessage="Secure"/></h3>
                                                <div><FormattedMessage id="label.home_h26" defaultMessage="User access is restricted by user-role permission for secure cloud storage. Antivirus and anti-catastrophe protection by AWS."/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/*<!--  =============  End Main Features Section   ==========    -->*/}


                            {/*<!--  =============    Browsers Support Section  ==========    -->*/}
                            <section id="register-organization">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h2 className="section-title"><FormattedMessage id="label.home_h27" defaultMessage="Browsers Support"/></h2>
                                        </div>
                                        <div className="col-md-4">
                                            <img src="./configurable/contents/images/browser_logos.png"
                                                 alt="browser_logos.png" width="100%" height="auto"/>
                                        </div>
                                        <div className="col-md-8 text">
                                            <p>
                                                <FormattedMessage id="label.home_h28" defaultMessage="Platform tested and supported on major browsers like Chrome, Firefox, Internet Explorer, Microsoft Edge, Opera and Safari for iOS."/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/*<!--  ===========  End Browsers Support Section  ==========    -->*/}


                        </div>

                    </div>
                </>


            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()} <FormattedMessage id="label.home_h29" defaultMessage="Powered By Vector Analytica,Inc"/></Footer>
        </Layout>
    );
};

export default Home;